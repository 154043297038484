<template>
  <div class="readme-article">
    <h1 id="客户管理">四、客户管理</h1>
    <h2 id="客户列表">1.客户列表</h2>
    <h3 id="客户数据">1.1客户数据</h3>
    <p>
      查看系统客户列表，包括客户头像、名称、所属客服、标签、添加时间等信息，点击详情按钮可查看客户详情。
    </p>
    <p>
      <img src="@/assets/img/scrm/4-1.png" />
    </p>
    <h3 id="客户详情">1.2客户详情</h3>
    <p>
       查看客户详情，顶部切换至所属客服可编辑客户标签、客户基本信息等；可切换客户信息分类查看客户概述、客户动态、聊天记录、所在群聊等信息。
    </p>
    <p>
      <img src="@/assets/img/scrm/4-2.png" />
    </p>
     <p>
      <img src="@/assets/img/scrm/4-3.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Scrm4-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>